import React, { useEffect, useRef, useState } from "react";

function AgeBreaks({ ageBreaksInitial, errorsInitial }) {
  const [ageBreaks, setAgeBreaks] = useState(ageBreaksInitial || []);
  const [errors, setErrors] = useState(errorsInitial || []);

  const breakStart = useRef(null);
  const breakEnd = useRef(null);

  useEffect(() => {
    const parent = document.querySelector("#feasibility_age_breaks_input");
    
    if (parent) {
      const existing = parent.querySelectorAll("input");
      existing.forEach(el => {
        el.remove();
      });
      ageBreaks.forEach(ageBreak => {
        const input = document.createElement("input");
        input.type = "text";
        input.name = "feasibility[age_breaks][]";
        input.value = ageBreak;
        parent.appendChild(input);
      });
    }
  }, [ageBreaks]);

  return (
    <>
      <div id="age_breaks_list">
        {ageBreaks.map((ageBreak, index) => (
          <div key={ageBreak} className="age-break-item">
            <span className="age-break-item-remove" onClick={() => handleDeleteItem(index)}>×</span>
            <span>{ageBreak}</span>
          </div>
        ))}
      </div>
      <br />
      <div id="add-age-break">
        <label htmlFor="Break_Start">Break start</label>
        <input type="number" name="break_start" id="break_start" ref={breakStart} onFocus={() => setErrors([]) } />

        <label htmlFor="Break_End">Break end</label>
        <input type="number" name="break_end" id="break_end" ref={breakEnd}/>
        
        <button id="add_age_break" onClick={handleAddBreak}>Add age break</button>
      </div>
      {errors?.map(error => (
        <p key={error} style={{
          color: "#932419",
          fontWeight: "bold",
          marginTop: "0.3em",
        }}>{error}</p>
      ))}
    </>
  );

  function handleAddBreak(e) {
    e.preventDefault();
    const breakStartValue = parseInt(breakStart.current.value);
    const breakEndValue = parseInt(breakEnd.current.value);

    if (breakStartValue < 14) {
      setErrors([...errors, "Break start must be greater than 14"]);
      return;
    }
    
    if (breakStartValue && breakEndValue) {
      
      let newAgeBreaks = [...ageBreaks];
      let positionToInsert = getPositionToInsert(newAgeBreaks, breakStartValue, breakEndValue);

      if (positionToInsert === -1) {
        setErrors([...errors, "The age break you entered is not valid. Please try again."]);
        return;
      }

      newAgeBreaks.splice(positionToInsert, 0, `${breakStartValue}-${breakEndValue}`);
      setAgeBreaks(newAgeBreaks);

      resetInputs();
    }
  }
  
  function getPositionToInsert(array, newStart, newEnd) {
    if (newStart === newEnd) return -1;
    if (newStart > newEnd) return -1;

    if (array.length === 0) return 0;

    if (array.length === 1) {
      const [aStart, aEnd] = array[0].split("-");
      if (newEnd < aStart) return 0;
      else if (newStart > aEnd) return 1;
      else return -1;
    }

    if (newStart > array[array.length - 1].split("-")[1]) return array.length;

    if (newEnd < array[0].split("-")[0]) return 0;

    for (let i = 0; i < array.length - 1; i++) {
      const aEnd = parseInt(array[i].split("-")[1]);
      const bStart = parseInt(array[i + 1].split("-")[0]);

      if (newStart > aEnd && newEnd < bStart) return i + 1;
    }

    return -1;
  }

  function handleDeleteItem(index) {
    const newAgeBreaks = [...ageBreaks];
    newAgeBreaks.splice(index, 1);
    setAgeBreaks(newAgeBreaks);
  }

  function resetInputs() {
    breakStart.current.value = '';
    breakEnd.current.value = '';
    breakStart.current.focus();
  }
};

export default AgeBreaks;